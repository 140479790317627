<template>
  <div>
    <div class="title">
      新闻动态
    </div>
    <div class="nav">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>新闻动态</el-breadcrumb-item>
        <el-breadcrumb-item>高考动态</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="list" v-loading='loading'>
      <router-link v-for="(item,i) in newsList" :to="{patch:'/Detail',name:'Detail',query:{id:item.id}}" :key="i" class="items">
        <el-row class="line">
          <el-col :span="19" class="title_n">{{ item.title }}</el-col>
          <el-col :span="3" :offset="2" class="time">{{ item.create_time.split(" ")[0] }}</el-col>
        </el-row>
      </router-link>
    </div>
    <div class="pagination">
      <el-pagination :page-size="pageSize" :total="total" class="page-box" layout="prev, pager, next" @current-change="pageChange" />
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsList",
  data() {
    return {
      type: 1,
      newsList: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      loading: true,
    };
  },
  watch: {
    $route() {
      this.type = this.$route.query.type;
      this.getNews();
    },
  },
  mounted() {
    this.type = this.$route.query.type;
    this.getNews();
  },
  methods: {
    getNews() {
      this.loading = true;
      this.$fecth
        .post("news/getData", {
          type: this.type,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        })
        .then((res) => {
          if (res) {
            this.loading = false;
            this.newsList = res.lists;
            this.total = res.total;
          }
        });
    },
    pageChange(page) {
      this.pageNum = page;
      this.getNews();
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  text-align: center;
  background: #5274db;
  height: 150px;
  line-height: 150px;
  font-size: 40px;
  color: white;
}

.nav {
  width: 1200px;
  margin: 20px auto;
}

.list {
  letter-spacing: 1px;
  width: 1200px;
  margin: 0 auto 30px;
  border-top: 1px solid #dfdfdf;

  .line {
    padding: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: black;
    border: 1px solid #dfdfdf;
    border-top: 0;
    .time {
      margin-top: 3px;
    }
    .title_n {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
.pagination {
  padding: 40px 0;

  .page-box {
    text-align: center;
  }
}
</style>